import {AccountManager} from "../../utils/services/accountManager";
import {startServiceWorker} from "../../utils/ServiceWorker/domServiceWorkerUtilities";

export {onload, startServiceWorker}

async function onload() {
    if (!await AccountManager.get()) {
        if (window.location.pathname.includes("logout")) window.location.href = '/'
        else (document.getElementById("message") as HTMLDivElement).innerHTML = `Please login to access this resource. <a href='/?redirect_uri=${encodeURIComponent(window.location.pathname)}'>Click here to login</a>.`
        return
    }

    navigator.serviceWorker.getRegistrations().then(async registrations => {
        console.log(registrations);

        if (registrations.length === 0) {
            // service worker was not registered

            await startServiceWorker()
            setTimeout(() => {
                window.location.reload()
            }, 3000)
            return
        }

        (document.getElementById("message") as HTMLDivElement).innerHTML = "The web app tried to access a page that does not exist. Please either <a href='/'>return home</a> or <a href='/pages/logout/index.min.html'>logout</a>." +
            "<br>" +

            "<br>" +
            "If this error keeps occouring, you may be able to fix it by <a href='javascript:SnapshotAPI.resetServiceWorker()'> resetting the service worker (requires an internet connection)</a>.<br><br>" + window.location.href + " (404)"
    });
}



export async function resetServiceWorker() {
    let reg = await navigator.serviceWorker.getRegistration('/serviceWorker.js')
    if (!navigator.onLine) {
        alert("You need an internet connect to do this")
        return
    } else if (reg) {
        await reg.unregister()
        window.location.reload()
    } else {
        alert("There is currently no service worker installed. Please restart the app. If the error still occurs, please contact info@farmmedix.com")
    }
}

onload()